import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/newstyle.scss'

import blogimg5 from '../assets/img/blogimg15.png'
import blogimg6 from '../assets/img/blogimg14.jpg'
import blogimg3 from '../assets/img/3news.jpg'
import blogimg2 from '../assets/img/4news.jpg'
import blogimg1 from '../assets/img/51news.png'
import blogimg from '../assets/img/6news.jpg'


import SingleBlog from './Article1'

const BlogList = () => {




  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" >
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Visiting the facilities of our partner JEPCO: Exciting takeaways!
				</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 23/07/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">We, representatives of the ARISE consortium, visited the JEPCO facility on July 2nd, a short ride from downtown Colchester.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='Visiting the facilities of our partner JEPCO: Exciting takeaways!'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg3}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Advancing the Arise Project: ALSYMEX's visit to “Les Fermes Nouvelles”
				</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 15/06/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Our partner ALSYMEX recently visited Les Fermes Nouvelles, unveiling promising advancements for the Arise project!</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Advancing the Arise Project: ALSYMEXs visit to Les Fermes Nouvelles'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
			  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg2}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">ARISE Project Shines at ICRA 2024
				</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 18/05/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Our esteemed partner, UBITECH, showcased groundbreaking advancements of the ARISE project at the prestigious ICRA 2024 conference in Yokohama, Japan. </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/ARISE Project Shines at ICRA 2024'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

			  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg1}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">ARISE Project Featured in ADRA-e Brochure

				</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 20/04/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">We are thrilled to announce that our Horizon Europe project, ARISE, has been included in the prestigious ADRA-e brochure. This inclusion highlights the innovative work being undertaken by our consortium, which is part of the AI, Data, and Robotics Co-programmed partnership.
				</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/ARISE Project Featured in ADRA-e Brochure'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
			  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg5}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Alongside Adra-e. Towards AI-driven autonomous robots.</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 23/03/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The ARISE Project recently participated in a significant launch event aimed at advancing research and innovation excellence in the fields of AI, Data, and Robotics</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Alongside Adra-e. Towards AI-driven autonomous robots'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg6}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Kick-off-Meeting in Athens</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						20/02/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">We are very excited to announce the start of the engaging journey of the ARISE project consortium which was sealed with a kick-off meeting! </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Kick-off-Meeting in Athens'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

		  

	
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default BlogList